/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import EmailAlert from "components/EmailAlert";

export default function CustomNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/landing-page" tag={Link} id="tooltip6619950104">
              <span>ST•</span> Safe Tech USA
            </NavbarBrand>
            {/* <UncontrolledTooltip delay={0} target="tooltip6619950104">
              Designed and Coded by Creative Tim
            </UncontrolledTooltip> */}
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a
                    href="#pablo"
                    style={{ color: "#000000" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    STUSA 🇺🇸
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    className="navbar-toggler"
                    style={{ color: "#000000" }}
                    id="navigation"
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" nav>
              <NavItem className="ml-5">
                <NavLink
                  className="navbar-text"
                  href="https://safetechusa.ai/landing-page"
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                {/* <NavLink
                  className="navbar-text"
                  href="https://safetechusa.ai/about-us"
                >
                  About Us
                </NavLink> */}
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
        <EmailAlert
          emailStatus={props.emailStatus}
          setEmailStatus={props.setEmailStatus}
        />
      </Navbar>
    </>
  );
}
