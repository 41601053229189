/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";

// core components
import CustomNavbar from "components/Navbars/CustomNavbar";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

export default function AboutUs() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("about-us");
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  }, []);

  return (
    <>
      <CustomNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          {/* ********* TEAM 5 ********* */}
          <div className="team-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                <img
                            alt="..."
                            className=""
                            src={require("assets/img/SafeTechLogo.jpeg")}
                          />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title mb-0">Meet The Team</h2>
                  {/* <h4 className="description">
                    This is the paragraph where you can write more details about
                    your team. Keep you user engaged by providing meaningful
                    information.
                  </h4> */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="4">
                  <Card className="card-profile profile-bg">
                    <CardHeader
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/ben-konfrst.jpg") + ")",
                      }}
                    >
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised"
                            src={require("assets/img/marcusCropped.png")}
                          />
                        </a>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">Marcus Flores</CardTitle>
                      <h6 className="category text-primary">Founder / CEO</h6>
                      {/* <p className="card-description">
                        Multi-Cloud Object Storage and Data Mobility: A GigaOm
                        Market Landscape Report has been announced...
                      </p> */}
                    </CardBody>
                    <CardFooter>
                      <div className="d-inline d-md-block">
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="dribbble"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-dribbble" />
                        </Button>
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="linkedin"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon btn-round"
                          color="behance"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-behance" />
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile profile-bg">
                    <CardHeader
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/waranont.jpg") + ")",
                      }}
                    >
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised"
                            src={require("assets/img/KeatonProfile.jpeg")}
                          />
                        </a>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">Keaton Krieger</CardTitle>
                      <h6 className="category text-primary">Founder / CTO</h6>
                      {/* <p className="card-description">
                        Over the years, advancement in CRM technology has
                        reshaped the way SMBs and SMEs manage their workflows...
                      </p> */}
                    </CardBody>
                    <CardFooter>
                      <div className="d-inline d-md-block">
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="dribbble"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-dribbble" />
                        </Button>
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="linkedin"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon btn-round"
                          color="behance"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-behance" />
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
   
              </Row>
            </Container>
          </div>
          {/* ********* END TEAM 5 ********* */}
        </div>
        <DemoFooter />
      </div>
    </>
  );
}
