import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Button, Input, Col, Row } from "reactstrap";

export const Email = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (form.current.checkValidity()) {
      emailjs
        .sendForm(
          "service_otip9ea",
          "template_9lem7vf",
          form.current,
          "RbW86w9ogsO-5UbkN"
        )
        .then(
          (result) => {
            console.log(result.text);
            props.setEmailStatus(true);
          },
          (error) => {
            console.log(error.text);
            props.setEmailStatus(false);
          }
        );
    } else {
      // The form is invalid, you can display an error message or take any other desired action
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <Row className="row-input">
        <Col className="p-2 mt-2" md="8" xs="12" sm="12">
          <Input
            type="email"
            name="user_email"
            placeholder="Please Enter Email to Join Waitlist"
            required // Mark the input as required
          />
        </Col>
        <Col md="4" xs="12" sm="12" className="p-2">
          <Button className="mt-2 md:mt-0" color="warning" type="submit" value="Send">
            Join Today
          </Button>
        </Col>
      </Row>
    </form>
  );
};
