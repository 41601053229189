import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const EmailAlert = (props) => {
  let navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [notificationColor, setNotifcationColor] = useState("info");
  const [notificationText, setNotifcationText] = useState({
    text1: "",
    text2: "",
  });

  const successText = {
    text1: "Waitlist Joined!",
    text2:
      "We will never distribute your email address to third parties. Read about email communication in our privacy policy.",
  };

  const warningText = {
    text1: "Email Failed!",
    text2: "Please try again later. Our email system is experiencing an issue.",
  };

  const onDismiss = () => {
    setVisible(false);
    props.setEmailStatus(undefined);
  };

  useEffect(() => {
    if (props.emailStatus == true) {
      // setVisible(true);
      // setNotifcationColor("info");
      // setNotifcationText(successText);
      navigate('/thank-you');

    } else if (props.emailStatus === false) {
      setVisible(true);
      setNotifcationColor("warning");
      setNotifcationText(warningText);
    }
  }, [props.emailStatus]);

  return (
    <Alert
      className="text-small fixed-top my-md"
      color={notificationColor}
      isOpen={visible}
      toggle={onDismiss}
    >
      <i className="icon-shield" />
      <span className="text-center">
        <strong>{notificationText.text1}</strong> {notificationText.text2}
      </span>
    </Alert>
  );
};

export default EmailAlert;
