/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import CustomNavbar from "components/Navbars/CustomNavbar";
import DemoFooter from "components/Footers/DemoFooter.js";

export default function Pricing() {
  const [emailFocus, setEmailFocus] = React.useState(undefined);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("pricing");
    return function cleanup() {
      document.body.classList.remove("pricing");
    };
  }, []);
  return (
    <>
      <CustomNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="header header-4">
          <div className="header-wrapper">
          </div>
          <div className="main">
            <div className="features-1">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <Badge color="primary" pill>
                      Insight
                    </Badge>
                    <h1 className="title">Privacy Policy</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="info info-hover">
                      <p className="description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="contactus-3">
              <div className="page-header header-filter">
                <div
                  className="page-header-image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/andre-benz.jpg") + ")",
                  }}
                />
                <Container>
                  <Row>
                    <Col className="text-center" md="12">
                      <h1 className="title">Got a question?</h1>
                      <h3>We'd like to talk more about what you need</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid>
                <Row>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-primary">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/primary.png")}
                        />
                        <i className="tim-icons icon-square-pin" />
                      </div>
                      <h4 className="info-title">Address</h4>
                      <p className="description">12124 First Street, nr 54</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-info">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/info.png")}
                        />
                        <i className="tim-icons icon-email-85" />
                      </div>
                      <h4 className="info-title">Email</h4>
                      <p className="description">support@youremail.com</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-warning">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/warning.png")}
                        />
                        <i className="tim-icons icon-mobile" />
                      </div>
                      <h4 className="info-title">Phone Number</h4>
                      <p className="description">+1(424) 535 3523</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-success">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/success.png")}
                        />
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h4 className="info-title">Contact</h4>
                      <p className="description">Andrew Samian</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section projects-2">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">We continually work to improve</h2>
                    <h4 className="description">
                      By accepting this, you will be kept up to date on new
                      developments concerning the CMS
                    </h4>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/main/material-kit-react/material-kit-react.jpeg"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <div className="stars text-warning">
                              <i className="tim-icons icon-shape-star" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                            </div>
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">Material Kit React</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/main/black-dashboard-react/black-dashboard-react.jpg"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <i className="tim-icons icon-heart-2 text-danger" />{" "}
                            342 ·{" "}
                            <i className="tim-icons icon-single-copy-04 text-info" />{" "}
                            43
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">Black Dashboard React</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/main/argon-dashboard-pro-react/argon-dashboard-pro-react.jpg"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <i className="tim-icons icon-heart-2 text-danger" />{" "}
                            127
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">
                              Argon Dashboard Pro React
                            </span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="subscribe-line">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="6" md="10">
                    <div className="text-center">
                      <h2 className="title">Subscribe to our Newsletter</h2>
                      <p className="description">
                        You'll be informed about updates, special offers and
                        planned changes..
                      </p>
                    </div>
                    <Card className="card-raised card-form-horizontal">
                      <CardBody>
                        <Form action="" method="">
                          <Row>
                            <Col sm="8">
                              <InputGroup
                                className={classnames({
                                  "input-group-focus": emailFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="tim-icons icon-email-85" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Your Email..."
                                  type="email"
                                  onFocus={(e) => setEmailFocus(true)}
                                  onBlur={(e) => setEmailFocus(false)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm="4">
                              <Button color="primary" type="button">
                                Subscribe
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <DemoFooter />
      </div>
    </>
  );
}
